import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

type NotFoundPageType = {
  data: GatsbyTypes.NotFoundPageQueryQuery
  location: Location
}

const NotFoundPage: React.FC<NotFoundPageType> = ({ data, location }) => {
  const siteTitle = data?.site?.siteMetadata?.title ?? `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <h1>404: Not Found</h1>
      <p>
        The server has not found anything matching the Request-URI. No
        indication is given of whether the condition is temporary or permanent.
        The 410 (Gone) status code SHOULD be used if the server knows, through
        some internally configurable mechanism, that an old resource is
        permanently unavailable and has no forwarding address. This status code
        is commonly used when the server does not wish to reveal exactly why the
        request has been refused, or when no other response is applicable.
      </p>
      <p>
        <a href="https://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html">
          <cite>Status Code Definitions</cite>
        </a>
      </p>
    </Layout>
  )
}

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage

export const pageQuery = graphql`
  query NotFoundPageQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
