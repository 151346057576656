import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

type LayoutType = {
  location: Location
  title: string
  fullWidth?: boolean
  children: React.ReactNode
}

const Layout: React.FC<LayoutType> = ({
  location,
  title,
  fullWidth,
  children,
}) => {
  const data = useStaticQuery<GatsbyTypes.LayoutQueryQuery>(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          author {
            name
          }
        }
      }
    }
  `)
  const author = data?.site?.siteMetadata?.author

  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location?.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <div
      className={fullWidth ? "global-full-wrapper" : "global-wrapper"}
      data-is-root-path={isRootPath}
    >
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer style={{ textAlign: `center` }}>
        © {new Date().getFullYear()}
        {author?.name && ` ${author.name}`}, Built with
        {` `}
        <a href="https://www.gatsbyjs.com">Gatsby</a>
      </footer>
    </div>
  )
}

export default Layout
