/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

type SeoType = {
  description?: string
  lang?: string
  title?: string
  image?: string
  children?: React.ReactNode
  article?: { pathname?: string }
  index?: { latestPathname?: string }
}

const Seo: React.FC<SeoType> = ({
  description,
  title,
  image,
  children,
  article,
  index,
}) => {
  const { site, newsletterOgImage } = useStaticQuery<GatsbyTypes.SeoQueryQuery>(
    graphql`
      query SeoQuery {
        site {
          siteMetadata {
            title
            newsletterTitle
            description
            siteUrl
            author {
              name
              summary
            }
            social {
              twitterId
              twitter
              revueId
            }
          }
        }
        newsletterOgImage: file(relativePath: { eq: "profile-pic.png" }) {
          childImageSharp {
            fixed(height: 768, width: 768) {
              src
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site?.siteMetadata?.description
  const defaultTitle = site?.siteMetadata?.title
  const summary = site?.siteMetadata?.author?.summary
  const siteRoot = site?.siteMetadata?.siteUrl ?? ""

  return (
    <>
      <title>{defaultTitle ? `${title} | ${defaultTitle}` : title}</title>
      <meta name="description" content={metaDescription ?? undefined} />
      <meta
        name="author"
        content={site?.siteMetadata?.author?.name ?? undefined}
      />
      {article ? (
        <>
          <meta property="og:type" content="article" />
          <meta property="twitter:newsletter:issue:card" content="true" />
          <meta
            property="twitter:issue:url"
            content={
              article.pathname
                ? `${siteRoot}${article.pathname}?via=twitter-card`
                : undefined
            }
          />
          <meta
            property="og:url"
            content={
              article.pathname ? `${siteRoot}${article.pathname}` : undefined
            }
          />
          <meta
            property="twitter:issue:number"
            content={
              article.pathname
                ? article.pathname
                    .split("-")
                    .slice(-2, -1)[0]
                    .split("/")
                    .slice(-1)[0]
                : undefined
            }
          />
          <meta property="twitter:issue:title" content={title} />
          <meta
            property="twitter:issue:description"
            content={metaDescription ?? undefined}
          />
          <meta
            property="og:description"
            content={metaDescription ?? undefined}
          />
          <meta
            property="twitter:description"
            content={metaDescription ?? undefined}
          />
        </>
      ) : index ? (
        <>
          <meta property="og:type" content="profile" />
          <meta property="twitter:newsletter:publication:card" content="true" />
          <meta property="twitter:newsletter:subscriber:count" content="999" />
          <meta
            property="twitter:newsletter:issue:count"
            content={
              index.latestPathname
                ? index.latestPathname
                    .split("-")
                    .slice(-2, -1)[0]
                    .split("/")
                    .slice(-1)[0]
                : undefined
            }
          />
          <meta
            property="twitter:newsletter:latest:issue:url"
            content={
              index.latestPathname
                ? `${siteRoot}${index.latestPathname}?via=twitter-card`
                : undefined
            }
          />
          <meta property="og:description" content={summary ?? undefined} />
          <meta property="twitter:description" content={summary ?? undefined} />
        </>
      ) : null}
      <meta
        property="twitter:newsletter:profile:url"
        content={siteRoot ? `${siteRoot}?via=twitter-card` : undefined}
      />
      <meta
        property="twitter:newsletter:title"
        content={site?.siteMetadata?.newsletterTitle ?? undefined}
      />
      <meta
        property="twitter:newsletter:image:src"
        content={newsletterOgImage?.childImageSharp?.fixed?.src}
      />
      <meta property="twitter:title" content={title} />
      <meta property="og:title" content={title} />
      <meta
        property="twitter:newsletter:description"
        content={summary ?? undefined}
      />
      <meta
        property="twitter:card"
        content={image ? "summary_large_image" : "summary"}
      />
      <meta
        property="twitter:revue:account:id"
        content={site?.siteMetadata?.social?.revueId ?? undefined}
      />
      <meta
        property="twitter:creator"
        content={site?.siteMetadata?.social?.twitter ?? undefined}
      />
      <meta
        property="twitter:user:id"
        content={site?.siteMetadata?.social?.twitterId ?? undefined}
      />
      <meta
        property="twitter:site"
        content={
          site?.siteMetadata?.social?.twitter
            ? `@${site?.siteMetadata?.social?.twitter}`
            : undefined
        }
      />
      {image && (
        <>
          <meta property="twitter:image" content={`${siteRoot}${image}`} />
          <meta
            property="twitter:issue:image:src"
            content={`${siteRoot}${image}`}
          />
        </>
      )}
      <meta
        property="og:image"
        content={`${siteRoot}${
          image ?? newsletterOgImage?.childImageSharp?.fixed?.src
        }`}
      />
      {children}
    </>
  )
}

Seo.defaultProps = {
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default Seo
